<template>
    <div class="archive">
        <h1>{{ $t('archive.title') }}</h1>
        
        <router-link to="/map/bridges">{{ $t('archive.bridges') }}</router-link>
        <router-link to="/map/seabridges">{{ $t('archive.seabridges') }}</router-link>
    </div>
</template>
  
<script>
export default {
    name: 'Archive'
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/sass/libs/_mixins.scss";
@import "@/assets/sass/libs/_vars.scss";

.archive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5%;
    max-width: 1400px;
    margin: auto;

    h1 {
        color: $red;
        font-family: "DIN-Condensed-Bold", sans-serif;
    }

    a {
        font-family: "DIN-Condensed-Bold", sans-serif;
        font-weight: bolder;
        color: $lightblue;
        font-size: 20px;
        margin: 5px 0;
        text-transform: uppercase;
        text-decoration: underline;

        &:hover {
            color: $blue;
        }
    }
}
</style>